import React, { useMemo, useState } from "react";

import { flag } from "country-emoji";
import { t } from "i18next";
import { useParams } from "react-router-dom";

import MeetingReportRowsDisplay from "@app/modules/crm/MeetingReportRowsDisplay";
import Collapse from "@components/data-display/Collapse";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tag from "@components/data-display/Tag";
import Loading from "@components/feedback/Loading";
import {
  DecreasingArrowIcon,
  IncreasingArrowIcon,
} from "@components/icons/meeting-report-evolution-icons";
import { ZonedDate } from "@helpers/Date";
// because there is already an interface called Appointment
import { Appointment as AppointmentModel } from "@models/Appointment";
import { CollectionInformationByAccount } from "@models/CollectionInformationByAccount";
import {
  AppointmentTypeEnum,
  ShowroomSeason,
  ShowroomSeasonList,
} from "@models/types/enums";
import { GetAccount } from "@services/api/accounts/get-account";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

interface Attendee {
  firstName: string;
  lastName: string;
}

interface MeetingReport {
  otb: number | null;
  actualBudget: number | null;
  notes: string | null;
}

interface Collection {
  id: string;
  name: string;
  collectionInformationByAccount: Pick<
    CollectionInformationByAccount,
    "status"
  >[];
}

interface Showroom {
  id: string;
  season: ShowroomSeason;
  year: number;
  timezone: string;
}

interface Appointment {
  id: string;
  accountOtb: number | null;
  startTime: ZonedDate;
  endTime: ZonedDate;
  format: AppointmentModel["format"];
  virtualMeetingApp: AppointmentModel["virtualMeetingApp"];
  meetingReport: MeetingReport | null;
  collection: Collection;
  attendees: Attendee[];
  showroom: Showroom;
  type: AppointmentTypeEnum;
}

interface Season {
  seasonAndYear: string;
  season: string;
  year: number;
  accountName: string;
  otbSum: number;
  totalBudgetSum: number;
  appointments: Appointment[];
}
// useState for tracking which table to collapse
interface CollapseStates {
  [key: string]: boolean;
}

// calculate sum of OTB for each season
function sumOtb(season: Season) {
  return season.appointments.reduce(
    (total: number, appointment: Appointment) => {
      // Check if otb exists and is a number
      if (
        appointment.meetingReport?.otb !== undefined &&
        typeof appointment.meetingReport.otb === "number"
      ) {
        return total + appointment.meetingReport.otb;
      }
      return total;
    },
    0,
  );
}

// calculate sum of budget for each season
function sumBudget(season: Season) {
  return season.appointments.reduce(
    (total: number, appointment: Appointment) => {
      if (
        appointment.meetingReport?.actualBudget !== undefined &&
        typeof appointment.meetingReport.actualBudget === "number"
      ) {
        return total + appointment.meetingReport.actualBudget;
      }
      return total;
    },
    0,
  );
}

function findOtbSumAndTotalBudgetSumEvolution(
  groupedAppointments: Season[],
  currentSeason: string,
  currentYear: number,
  currentValue: number,
  valueType: "otbSum" | "totalBudgetSum",
): [number | string, string, string] {
  const previousYearSeason = groupedAppointments.find(
    (season) =>
      season.year === currentYear - 1 && season.season === currentSeason,
  );

  let evolutionPercentage: number | string | null;
  let evolutionArrow: string;
  let evolutionColor: string;
  let previousValue: number | null;

  if (!previousYearSeason) {
    evolutionPercentage = "-";
    evolutionArrow = "none";
    evolutionColor = "gray";
    return [evolutionPercentage, evolutionArrow, evolutionColor];
  }

  previousValue = previousYearSeason.otbSum;

  if (valueType === "otbSum") {
    previousValue = previousYearSeason.otbSum;
  } else if (valueType === "totalBudgetSum") {
    previousValue = previousYearSeason.totalBudgetSum;
  } else {
    throw new Error("Unsupported value type");
  }

  // check if there is no value for the previous season or current season
  if (
    !previousValue ||
    previousValue === 0 ||
    !currentValue ||
    currentValue === 0
  ) {
    evolutionPercentage = "-";
    evolutionArrow = "none";
    evolutionColor = "gray";
  }
  // check if the value is the same for the current and previous season
  else if (previousValue === currentValue) {
    evolutionPercentage = 0;
    evolutionArrow = "equal";
    evolutionColor = "gray";
  }
  // check if the value for the previous season is bigger than the one for current season
  else if (previousValue > currentValue) {
    evolutionPercentage = (1 - currentValue / previousValue) * 100;

    if (!Number.isInteger(evolutionPercentage)) {
      evolutionPercentage = evolutionPercentage.toFixed(1);
    }

    evolutionArrow = "decreasing";
    evolutionColor = "red";
  }
  // check if the value for the previous season is smaller than the one for current season
  else {
    evolutionPercentage = (currentValue / previousValue - 1) * 100;

    if (!Number.isInteger(evolutionPercentage)) {
      evolutionPercentage = evolutionPercentage.toFixed(1);
    }
    evolutionArrow = "increasing";
    evolutionColor = "green";
  }

  return [evolutionPercentage, evolutionArrow, evolutionColor];
}

export default function AccountMeetingReports() {
  // fetch accountId
  const { accountId } = useParams();

  if (!accountId) throw new Error("No accountId in the params");

  // fetch organizationId
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // fetch data
  const { data: account, status } = GetAccount.useHook({
    organizationId,
    accountId,
  });

  //* group appointments by season
  // modify the appointment structure so that I can list the appointments by season
  const appointmentsBySeason = useMemo(() => {
    if (account) {
      const groupedAppointments = account?.appointments.reduce(
        (acc, appointment) => {
          const { season, year } = appointment.showroom;
          const seasonYear = `${season} ${year}`;

          let seasonObj = acc.find((s) => s.seasonAndYear === seasonYear);

          if (!seasonObj) {
            seasonObj = {
              seasonAndYear: seasonYear,
              season,
              year,
              accountName: account.name,
              appointments: [],
              otbSum: 0,
              totalBudgetSum: 0,
            };
            acc.push(seasonObj);
          }

          seasonObj.appointments.push({
            id: appointment.id,
            startTime: appointment.startTime,
            endTime: appointment.endTime,
            format: appointment.format,
            virtualMeetingApp: appointment.virtualMeetingApp,
            meetingReport: appointment.meetingReport,
            collection: appointment.collection,
            attendees: appointment.attendees,
            showroom: appointment.showroom,
            type: appointment.type,
            accountOtb: appointment.accountOtb,
          });

          // accumulate OTB values
          if (typeof appointment.meetingReport?.otb === "number") {
            seasonObj.otbSum += appointment.meetingReport.otb;
          }

          // accumulate OTB values
          if (typeof appointment.meetingReport?.actualBudget === "number") {
            seasonObj.totalBudgetSum += appointment.meetingReport.actualBudget;
          }

          return acc;
        },
        [] as Season[],
      );

      // Sort seasons by year and by season name
      groupedAppointments.sort((a, b) => {
        const seasonA = a.season as ShowroomSeason;
        const seasonB = b.season as ShowroomSeason;
        if (a.year === b.year) {
          return (
            ShowroomSeasonList.indexOf(seasonA) -
            ShowroomSeasonList.indexOf(seasonB)
          );
        }
        return b.year - a.year;
      });

      return groupedAppointments;
    }
    return [];
  }, [account]);

  // track collapse states
  const [collapseStates, setCollapseStates] = useState<CollapseStates>(
    appointmentsBySeason.reduce((acc, _, index) => {
      const key = `isCollapsed-${index}`;
      return { ...acc, [key]: false };
    }, {}),
  );

  if (status === "pending") {
    return <Loading type="screen" />;
  }

  if (status === "error") {
    throw new Error("could not fetch account");
  }

  return (
    <>
      <PageHeader>
        <div className="flex flex-row ">
          <PageTitle>{account?.name}</PageTitle>
          {/* If there is no countryCode, then set "fr" as default */}
          <Tag icon={flag(account?.countryCode || "fr")} className="w-fit mx-5">
            {account?.city}
          </Tag>
        </div>
      </PageHeader>
      {appointmentsBySeason.length > 0 ? (
        <div>
          <div className="mt-10 mb-2 px-4 mx-1 ml-10 grid grid-cols-6">
            <p className="font-light text-left px-4 truncate">
              {t("CRM.accounts.meeting-reports.table-header.collection")}
            </p>
            <p className="font-light text-left px-4 truncate">
              {t("CRM.accounts.meeting-reports.table-header.contact")}
            </p>
            <p className="font-light text-left px-4 truncate">
              {t("CRM.accounts.meeting-reports.table-header.otb")}
            </p>
            <p className="font-light text-left px-4 truncate">
              {t("CRM.accounts.meeting-reports.table-header.budget")}
            </p>
            <p className="font-light text-left px-4 truncate">
              {t("CRM.accounts.meeting-reports.table-header.notes")}
            </p>
            <p className="font-light text-left">{}</p>
          </div>
          <hr />
          <div className="p-6">
            {appointmentsBySeason.map((season, index) => {
              const [
                otbSumEvolutionPercentage,
                otbSumEvolutionArrow,
                otbSumEvolutionColor,
              ] = findOtbSumAndTotalBudgetSumEvolution(
                appointmentsBySeason,
                season.season,
                season.year,
                season.otbSum,
                "otbSum",
              );

              const [
                totalBudgetSumEvolutionPercentage,
                totalBudgetSumEvolutionArrow,
                totalBudgetSumEvolutionColor,
              ] = findOtbSumAndTotalBudgetSumEvolution(
                appointmentsBySeason,
                season.season,
                season.year,
                season.totalBudgetSum,
                "totalBudgetSum",
              );

              return (
                <Collapse
                  title={
                    <div className="w-full grid grid-cols-6 ">
                      <div className="mx-0 px-0 ">
                        <span className="font-bold text-xl truncate flex items-start px-4">{`${season.seasonAndYear} (${season.appointments.length})`}</span>
                      </div>
                      <div className="col-start-3 text-left px-3 trucnate">
                        {/* ------------ OTB SUM EVOLUTION */}
                        <div className="flex flex-row">
                          <span className="text-primaryElectricBlue inline-block overflow-hidden whitespace-nowrap">
                            {`Sum: ${sumOtb(season)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`}
                          </span>
                          <span
                            className={` inline-block overflow-hidden whitespace-nowrap ml-2 text-${otbSumEvolutionColor}-500`}
                          >
                            {otbSumEvolutionPercentage}%
                          </span>
                          <span className="inline-block overflow-hidden whitespace-nowrap ml-2">
                            {otbSumEvolutionArrow === "increasing" && (
                              <IncreasingArrowIcon />
                            )}
                            {otbSumEvolutionArrow === "decreasing" && (
                              <DecreasingArrowIcon />
                            )}
                            {otbSumEvolutionArrow === "equal" && (
                              <span className="text-gray-500">=</span>
                            )}
                            {otbSumEvolutionArrow === "none" && <span />}
                          </span>
                        </div>
                      </div>
                      <div className="col-start-4 px-3 text-left">
                        {/* ------------ TOTAL BUDGET SUM EVOLUTION */}
                        <div className="flex flex-row">
                          <span className="text-primaryElectricBlue inline-block overflow-hidden whitespace-nowrap">
                            {`Sum: ${sumBudget(season)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`}
                          </span>
                          <span
                            className={`inline-block overflow-hidden whitespace-nowrap ml-2 text-${totalBudgetSumEvolutionColor}-500`}
                          >
                            {totalBudgetSumEvolutionPercentage}%
                          </span>
                          <span className="inline-block overflow-hidden whitespace-nowrap ml-2">
                            {totalBudgetSumEvolutionArrow === "increasing" && (
                              <IncreasingArrowIcon />
                            )}
                            {totalBudgetSumEvolutionArrow === "decreasing" && (
                              <DecreasingArrowIcon />
                            )}
                            {totalBudgetSumEvolutionArrow === "equal" && (
                              <span className="text-gray-500">=</span>
                            )}
                            {totalBudgetSumEvolutionArrow === "none" && (
                              <span />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                  className={`gap-2mt-2mb-0 px-4 w-full ${
                    collapseStates[`isCollapsed-${index}`]
                      ? "border border-dashed rounded-lg block border-primaryElectricBlue p-8"
                      : "border rounded-lg border-primaryGrey p-8 "
                  }`}
                  buttonClassName="!px-0 !pt-0 "
                  contentClassName="!px-0 !pb-0 mb-4 "
                  collapsed={collapseStates[`isCollapsed-${index}`]}
                  onToggle={() => {
                    setCollapseStates((prevStates) => ({
                      ...prevStates,
                      [`isCollapsed-${index}`]:
                        !prevStates[`isCollapsed-${index}`],
                    }));
                  }}
                  titleJustify="between"
                >
                  {season.appointments.map((appointment, i) => (
                    <MeetingReportRowsDisplay
                      key={i}
                      appointment={appointment}
                      account={account}
                    />
                  ))}
                </Collapse>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-64">
          <p className="text-lg text-gray-500">
            {t("CRM.accounts.meeting-reports.no-meeting-report")}
          </p>
        </div>
      )}
    </>
  );
}
