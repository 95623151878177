import React from "react";

import { t } from "i18next";
import { HiDocumentText } from "react-icons/hi2";

import MeetingReportForm from "@app/modules/crm/components/account/meeting-report/form";
import Button from "@components/data-entry/Button";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import {
  DecreasingArrowIcon,
  IncreasingArrowIcon,
} from "@components/icons/meeting-report-evolution-icons";
import { ZonedDate } from "@helpers/Date";
import { Account } from "@models/Account";
import { Appointment as AppointmentModel } from "@models/Appointment";
import { CollectionInformationByAccount } from "@models/CollectionInformationByAccount";
import { ShowroomSeason } from "@models/types/enums";

import AppointmentCard from "../../components/data-display/AppointmentCard";
import { BlueRow } from "../../components/data-display/BlueRow";
import Tooltip from "../../components/data-display/Tooltip";

const isMeetingReportFullyFilled = (
  meetingReport:
    | Pick<MeetingReport, "actualBudget" | "otb" | "notes">
    | null
    | undefined,
) =>
  meetingReport !== null &&
  meetingReport !== undefined &&
  meetingReport.actualBudget !== null &&
  meetingReport.otb !== null &&
  meetingReport.notes !== null;

interface Showroom {
  id: string;
  season: ShowroomSeason;
  year: number;
  timezone: string;
}

interface MeetingReport {
  otb: number | null;
  actualBudget: number | null;
  notes: string | null;
}
interface Collection {
  id: string;
  name: string;
  collectionInformationByAccount: Pick<
    CollectionInformationByAccount,
    "status"
  >[];
}

interface Attendee {
  firstName: string;
  lastName: string;
}

interface Appointment {
  showroom: Showroom;
  id: string;
  accountOtb: number | null;
  startTime: ZonedDate;
  endTime: ZonedDate;
  format: AppointmentModel["format"];
  virtualMeetingApp: AppointmentModel["virtualMeetingApp"];
  type: AppointmentModel["type"];
  meetingReport: MeetingReport | null;
  collection: Collection;
  attendees: Attendee[];
}

interface AccountData {
  id: string;
  type: Account["type"];
  name: string;
  city: string;
  countryCode: string;
  status: Account["status"];
  continentCode: string;
  isKeyClient: boolean;
  appointments: Appointment[];
}

interface MeetingReportDisplayProps {
  appointment: Appointment;
  account: AccountData;
}

function findOtbAndTotalBudgetEvolution(
  appointments: {
    showroom: Pick<Showroom, "season" | "year">;
    collection: Pick<Collection, "id">;
    meetingReport: Pick<MeetingReport, "otb" | "actualBudget"> | null;
  }[],
  currentSeason: string,
  currentYear: number,
  collectionId: string,
  currentValue: number,
  valueType: "otb" | "totalBudget",
): [number | string, string, string] {
  const previousYearAppointment = appointments.find(
    (appointment) =>
      appointment.showroom.season === currentSeason &&
      appointment.showroom.year === currentYear - 1 &&
      appointment.collection.id === collectionId,
  );

  let evolutionPercentage: number | string | null;
  let evolutionArrow: string;
  let evolutionColor: string;
  let previousValue: number | null;

  if (!previousYearAppointment || !previousYearAppointment.meetingReport) {
    evolutionPercentage = "-";
    evolutionArrow = "none";
    evolutionColor = "gray";
    return [evolutionPercentage, evolutionArrow, evolutionColor];
  }

  if (valueType === "otb") {
    previousValue = previousYearAppointment.meetingReport.otb;
  } else if (valueType === "totalBudget") {
    previousValue = previousYearAppointment.meetingReport.actualBudget;
  } else {
    throw new Error("Unsupported value type");
  }

  // check if there is no value for the previous season or current season
  if (
    !previousValue ||
    previousValue === 0 ||
    !currentValue ||
    currentValue === 0
  ) {
    evolutionPercentage = "-";
    evolutionArrow = "-";
    evolutionColor = "gray";
  }
  // check if the value is the same for the current and previous season
  else if (previousValue === currentValue) {
    evolutionPercentage = 0;
    evolutionArrow = "equal";
    evolutionColor = "gray";
  }
  // check if the value for the previous season is bigger than the one for current season
  else if (previousValue > currentValue) {
    evolutionPercentage = (1 - currentValue / previousValue) * 100;

    if (!Number.isInteger(evolutionPercentage)) {
      evolutionPercentage = evolutionPercentage.toFixed(1);
    }

    evolutionArrow = "decreasing";
    evolutionColor = "red";
  }
  // check if the value for the previous season is smaller than the one for current season
  else {
    evolutionPercentage = (currentValue / previousValue - 1) * 100;

    if (!Number.isInteger(evolutionPercentage)) {
      evolutionPercentage = evolutionPercentage.toFixed(1);
    }
    evolutionArrow = "increasing";
    evolutionColor = "green";
  }

  return [evolutionPercentage, evolutionArrow, evolutionColor];
}

function MeetingReportRowsDisplay({
  appointment,
  account,
}: MeetingReportDisplayProps) {
  const AppointmentReportDrawer = useDrawer();

  const [otbEvolutionPercentage, otbEvolutionArrow, otbEvolutionColor] =
    findOtbAndTotalBudgetEvolution(
      account.appointments,
      appointment.showroom.season,
      appointment.showroom.year,
      appointment.collection.id,
      appointment.meetingReport?.otb ?? 0,
      "otb",
    );

  // Calculate Total Budget evolution
  const [
    totalBudgetEvolutionPercentage,
    totalBudgetEvolutionArrow,
    totalBudgetEvolutionColor,
  ] = findOtbAndTotalBudgetEvolution(
    account.appointments,
    appointment.showroom.season,
    appointment.showroom.year,
    appointment.collection.id,
    appointment.meetingReport?.actualBudget ?? 0,
    "totalBudget",
  );

  return (
    <BlueRow colsClass="grid-cols-6">
      <p className="py-3 px-4 font-light text-left truncate">
        {appointment.collection.name}
      </p>
      <Tooltip
        placement="bottom-start"
        renderIf={appointment.attendees.length > 2}
        fallbackProp="children"
        content={
          <div className="min-w-[5rem] max-w-[21rem]">
            {appointment.attendees
              .map((attendee) => `${attendee.firstName} ${attendee.lastName}`)
              .join(", ")}
          </div>
        }
      >
        <p className="py-3 px-4 font-light text-left truncate">
          {appointment.attendees
            .map((attendee) => `${attendee.firstName} ${attendee.lastName}`)
            .join(", ")}
        </p>
      </Tooltip>
      {/* ------------ OTB EVOLUTION */}
      <div className="flex flex-row">
        <p className="py-3 px-4 font-light text-left truncate">
          {appointment.meetingReport?.otb
            ? `${appointment.meetingReport.otb
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`
            : "-"}
        </p>
        <span
          className={`py-3 font-light text-left truncate text-${otbEvolutionColor}-500`}
        >
          {otbEvolutionPercentage}%
        </span>
        <span className="py-3 font-light text-left truncate ml-1">
          {otbEvolutionArrow === "increasing" && <IncreasingArrowIcon />}
          {otbEvolutionArrow === "decreasing" && <DecreasingArrowIcon />}
          {otbEvolutionArrow === "equal" && (
            <span className="text-gray-500">=</span>
          )}
          {otbEvolutionArrow === "none" && <span />}
        </span>
      </div>
      {/* ------------ TOTAL BUDGET EVOLUTION */}
      <div className="flex flex-row">
        <p className="py-3 px-4 font-light text-left truncate">
          {/* adding a space between every group of three digits */}
          {appointment.meetingReport?.actualBudget
            ? `${appointment.meetingReport.actualBudget
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`
            : "-"}
        </p>
        <span
          className={`py-3 font-light text-left truncate text-${totalBudgetEvolutionColor}-500`}
        >
          {totalBudgetEvolutionPercentage}%
        </span>
        <span className="py-3 font-light text-left truncate ml-1">
          {totalBudgetEvolutionArrow === "increasing" && (
            <IncreasingArrowIcon />
          )}
          {totalBudgetEvolutionArrow === "decreasing" && (
            <DecreasingArrowIcon />
          )}
          {totalBudgetEvolutionArrow === "equal" && (
            <span className="text-gray-500">=</span>
          )}
          {totalBudgetEvolutionArrow === "none" && <span />}
        </span>
      </div>
      <Tooltip
        placement="bottom-start"
        renderIf={(appointment.meetingReport?.notes?.length ?? 0) > 10}
        fallbackProp="children"
        content={
          <div className="min-w-[5rem] max-w-[21rem]">
            {appointment.meetingReport?.notes}
          </div>
        }
      >
        <p className="py-3 px-4 font-light text-left truncate">
          {appointment.meetingReport?.notes
            ? appointment.meetingReport.notes
            : "-"}
        </p>
      </Tooltip>
      <Button
        theme="SECONDARY"
        onClick={AppointmentReportDrawer.openDrawer}
        className="w-fit h-[2.5rem] m-auto flex px-3 mr-3"
      >
        {isMeetingReportFullyFilled(appointment.meetingReport) ? (
          <HiDocumentText className="text-statusGreenDark" />
        ) : (
          <HiDocumentText className="text-primaryDarkGrey" />
        )}
        <span className="hidden xl:inline">
          {t("CRM.accounts.meeting-reports.full-report")}
        </span>
      </Button>
      <Drawer
        {...AppointmentReportDrawer.props}
        isOpen={AppointmentReportDrawer.isOpen}
        name="creating or updating appointment report drawer"
        backdrop
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">Appointment Report</h2>
        }
        size="LARGE"
      >
        <AppointmentCard
          appointmentId={appointment.id}
          accountId={account.id}
        />
        {appointment && account && (
          <MeetingReportForm
            accountId={account.id}
            appointmentId={appointment?.id}
            showroomId={appointment?.showroom.id}
            // Ensure meetingReport is never null by providing a default object if it is null
            meetingReport={
              appointment.meetingReport ?? {
                otb: null,
                actualBudget: null,
                notes: null,
              }
            }
            // Ensure accountOtb is a number or null as expected by MeetingReportFormProps
            // If appointment.meetingReport?.otb is not a number, default to null
            accountOtb={
              typeof appointment.accountOtb === "number"
                ? appointment.accountOtb
                : null
            }
            contactBooking={appointment.attendees[0].firstName}
            contentStyle="drawer"
            textarea
            closeDrawer={AppointmentReportDrawer.closeWithConfirmation}
          />
        )}
      </Drawer>
    </BlueRow>
  );
}

export default MeetingReportRowsDisplay;
