import React from "react";

import { flag } from "country-emoji";
import { FaWalking } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi2";
import { TiShoppingCart } from "react-icons/ti";

import MeetingReportForm from "@app/modules/crm/components/account/meeting-report/form";
import AppointmentCard from "@components/data-display/AppointmentCard";
import { BlueRow } from "@components/data-display/BlueRow";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import { formatTimeInterval } from "@helpers/Date";
import { MeetingReport } from "@models/MeetingReport";
import { GetAccount } from "@services/api/accounts/get-account";
import { GetAppointment } from "@services/api/appointments/get-appointment";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";

interface MeetingReportDisplayProps {
  appointmentId: string;
  accountId: string;
}

const isMeetingReportFullyFilled = (
  meetingReport:
    | Pick<MeetingReport, "actualBudget" | "otb" | "notes">
    | null
    | undefined,
) =>
  meetingReport !== null &&
  meetingReport !== undefined &&
  meetingReport.actualBudget !== null &&
  meetingReport.otb !== null &&
  meetingReport.notes !== null;

export function TodaysAppointmentsRowsDisplay({
  appointmentId,
  accountId,
}: MeetingReportDisplayProps) {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  // fetch appointment
  const { data: appointment } = GetAppointment.useHook({
    organizationId,
    appointmentId,
  });

  // fetch account
  const { data: account } = GetAccount.useHook({
    organizationId,
    accountId,
  });

  const startTimeAtTimezone = appointment?.startTime.toLocalDate(
    appointment.showroom.timezone,
  );
  const endTimeAtTimezone = appointment?.endTime.toLocalDate(
    appointment.showroom.timezone,
  );

  const AppointmentReportDrawer = useDrawer();

  // check if appointment has an account and collection, if not then I am returnng null/not rendering

  if (appointment === null || appointment === undefined) {
    LogService.error("Missing appointment, account or collection");
    return null;
  }

  const tooltipContent =
    appointment.type === "WALKTHROUGH" ? "Walkthrough" : "Buying Appointment";

  return (
    <BlueRow colsClass="grid-cols-16">
      <div className="py-2 px-2 font-light text-left col-span-1">
        <Tooltip
          placement="bottom-start"
          fallbackProp="children"
          content={
            <div className="text-center">
              <p>{tooltipContent}</p>
            </div>
          }
        >
          <div className="py-2 px-2 flex justify-center items-center">
            {appointment.type === "WALKTHROUGH" ? (
              <FaWalking />
            ) : (
              <TiShoppingCart />
            )}
          </div>
        </Tooltip>
      </div>
      <div className="py-2 px-2 flex items-center  truncate col-span-3">
        <Tooltip
          placement="bottom-start"
          fallbackProp="children"
          content={
            <p className="text-center truncate  ">
              {" "}
              {appointment?.account.name}
            </p>
          }
          renderIf={appointment.account.name.length > 22}
          className="col-span-3"
        >
          <p className="py-2 px-2 font-light text-left text-sm flex items-center">
            {appointment?.account.name}
          </p>
        </Tooltip>
      </div>
      <p className="py-2 px-2 font-light text-left truncate text-sm  flex items-center col-span-2">
        <Tag
          icon={flag(appointment?.account.countryCode)}
          className="w-fit flex items-center border border-none"
          type="default"
        >
          {appointment?.account.city}
        </Tag>
      </p>
      {appointment?.collection?.name ? (
        <div className="py-2 px-2 flex items-center truncate  col-span-2">
          <Tooltip
            placement="bottom-start"
            fallbackProp="children"
            content={
              <div className="text-center">
                <p>{appointment.collection?.name}</p>
              </div>
            }
            renderIf={appointment.collection.name.length > 21}
            className="col-span-3"
          >
            <p className="py-2 px-2 font-light text-left truncate text-sm  flex items-center">
              {appointment.collection.name}
            </p>
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center truncate col-span-2">
          <p className="py-2 px-2 font-light text-left text-sm flex items-center">
            -
          </p>
        </div>
      )}
      <p className="py-2 px-2 font-light text-left truncate text-sm col-span-1 flex items-center">
        {appointment.meetingReport?.otb
          ? `${appointment.meetingReport.otb
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`
          : "-"}
      </p>
      <p className="py-2 px-2 font-light text-left truncate text-sm col-span-2 flex items-center">
        {appointment?.meetingReport?.actualBudget
          ? `${appointment.meetingReport.actualBudget
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}€`
          : "-"}
      </p>
      <p className="py-2 px-2 font-light text-left truncate text-sm col-span-2 flex items-center">
        {startTimeAtTimezone &&
          endTimeAtTimezone &&
          formatTimeInterval(startTimeAtTimezone, endTimeAtTimezone)}
      </p>
      <div className="py-2 px-2 flex items-center truncate col-span-2">
        <Tooltip
          placement="bottom-start"
          fallbackProp="children"
          content={
            <div className="text-center">
              <p>
                {appointment?.seller.firstName}{" "}
                {appointment?.seller.lastName[0]}.
              </p>
            </div>
          }
          renderIf={
            `${appointment?.seller.firstName || ""} ${appointment?.seller.lastName ? appointment?.seller.lastName[0] : ""}.`
              .length > 13
          }
          className="col-span-2"
        >
          <p className="py-2 px-2 font-light text-left truncate text-sm flex items-center">
            {appointment?.seller.firstName} {appointment?.seller.lastName[0]}.
          </p>
        </Tooltip>
      </div>
      <Button
        theme="SECONDARY"
        onClick={AppointmentReportDrawer.openDrawer}
        className="w-fit h-[2.5rem] m-auto flex px-3 mr-3"
      >
        {isMeetingReportFullyFilled(appointment?.meetingReport) ? (
          <HiDocumentText className="text-statusGreenDark" />
        ) : (
          <HiDocumentText className="text-primaryDarkGrey" />
        )}
      </Button>
      <Drawer
        {...AppointmentReportDrawer.props}
        isOpen={AppointmentReportDrawer.isOpen}
        name="creating or updating appointment report drawer"
        backdrop
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">Appointment Report</h2>
        }
        size="LARGE"
      >
        {account && (
          <AppointmentCard
            appointmentId={appointmentId}
            accountId={appointment.account?.id}
          />
        )}
        {appointment && account && (
          <MeetingReportForm
            accountId={account.id}
            appointmentId={appointment?.id}
            showroomId={appointment?.showroom.id}
            // Ensure meetingReport is never null by providing a default object if it is null
            meetingReport={
              appointment.meetingReport ?? {
                otb: null,
                actualBudget: null,
                notes: null,
              }
            }
            accountOtb={
              typeof appointment.accountOtb === "number"
                ? appointment.accountOtb
                : null
            }
            contactBooking={appointment.attendees[0].firstName}
            contentStyle="drawer"
            textarea
            closeDrawer={AppointmentReportDrawer.closeWithConfirmation}
          />
        )}
      </Drawer>
    </BlueRow>
  );
}
